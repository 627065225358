<template>
  <div class="navbar-container d-flex justify-content-between">
    <!-- Nav Menu Toggler -->
    <div class="d-flex align-items-center gap-5">
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link class="nav-link p-0" @click="toggleVerticalMenuActive">
            <b-avatar variant="light-danger">
              <feather-icon icon="MenuIcon" size="21" />
            </b-avatar>
          </b-link>
        </li>
      </ul>
      <div class="d-flex gap-5" v-if="user && user.role.group === 'physician'">
        <b-link v-if="checkRoute" @click="showCompanyCollapse" class="nav-link p-0 m-0">
          <b-button :class="windowWidth < 767 ? 'py-75 px-50' : 'p-75'" style="border-radius: 30px" variant="gradient-primary">
            <span :class="windowWidth < 767 ? 'fs-13' : 'fs-14'">{{ selectedCompany ? $fn(selectedCompany.name, windowWidth < 767 ? 10 : 20) : "Firmalar" }}</span>
            <feather-icon class="ml-25" :icon="isCompanyCollapseVisible ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
        </b-link>
        <b-link v-if="checkRoute && selectedCompany && assignedBranches.length > 1" class="nav-link p-0 m-0">
          <b-button @click="showBranchCollapse" :class="windowWidth < 767 ? 'py-75 px-50' : 'p-75'" style="border-radius: 30px" variant="gradient-primary">
            <span :class="windowWidth < 767 ? 'fs-13' : 'fs-14'">{{ windowWidth < 767 ? "S" : branchNameCheck }}</span>
            <feather-icon class="ml-25" :icon="isBranchCollapseVisible ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
        </b-link>
      </div>
    </div>
    <!-- Center -->
    <!--  <div style="flex: 1 1">
      <customer-search-bar />
      <div class="d-flex gap-5" v-if="user && user.role.group === 'customer'">
        <app-nav-button variant="success" icon="PhoneCallIcon" />
        <app-nav-button icon="AlertTriangleIcon" />
        <app-nav-button variant="primary" icon="UserPlusIcon" />
      </div>
    </div> -->

    <div></div>

    <b-navbar-nav class="nav align-items-center gap-7">
      <!--  <app-dropdown /> -->
      <custom-search />
      <dark-Toggler />
      <notification-dropdown class="border-left" />
      <!-- <upgrade-button /> -->
    </b-navbar-nav>
  </div>
</template>

<script>
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import AppDropdown from "@core/layouts/components/app-navbar/components/AppDropdown.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import NotificationDropdown from "./NotificationDropdown.vue";
import customerCallRequest from "./customerCallRequest.vue";
import customSearch from "./customSearch/SearchBar.vue";
import upgradeButton from "./upgradeButton.vue";
import { $themeConfig } from "@themeConfig";
import isgRoutes from "@/router/routes/physician/isg";
const { appLogoImage } = $themeConfig.app;

export default {
  components: {
    // Navbar Components
    DarkToggler,
    AppDropdown,
    NotificationDropdown,
    FeatherIcon,
    customSearch,
    upgradeButton,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      customerDropdown: [
        {
          title: "Profil Bilgileri",
          icon: "UserIcon",
          route: "customer-profile",
        },
        {
          title: "Girişler ve Güvenlik",
          icon: "UserIcon",
          route: "customer-profile",
        },
        {
          title: "Müşteri Temsilciniz",
          icon: "PhoneCallIcon",
          route: "customer-profile",
        },
        {
          title: "Şikayet & İstek",
          icon: "MailIcon",
          route: "customer-profile",
        },
      ],
      physicianDropdown: [
        {
          title: "Kullanıcı Ayarları",
          icon: "UserIcon",
          route: "physician-profile",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    assignedBranches() {
      return this.$store.getters.assignedBranches;
    },
    branchNameCheck() {
      const selectedBranch = this.$store.getters.selectedBranch;
      return selectedBranch ? this.assignedBranches.find((branch) => branch._id === selectedBranch)?.name : "Tüm Siciller";
    },
    items() {
      if (this.user) {
        return this.user.role.group === "customer" ? this.customerDropdown : this.physicianDropdown;
      }
      return [];
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
    checkRoute() {
      return Boolean(isgRoutes.find((route) => route.name === this.$route.name));
    },
    isCompanyCollapseVisible() {
      return this.$store.getters.isCompanyCollapseVisible;
    },
    isBranchCollapseVisible() {
      return this.$store.getters.isBranchCollapseVisible;
    },
  },

  methods: {
    logout() {
      sessionStorage.removeItem("token");
      this.$store.commit("cleanStore");
      window.open("https://riskomer.com/logout", "_self");
    },
    showCompanyCollapse() {
      this.$store.commit("setBranchCollapseVisible", false);
      this.$store.commit("setCompanyCollapseVisible", !this.isCompanyCollapseVisible);
    },
    showCallModal() {
      this.$showAppModal({ title: "Arama Talebi", component: customerCallRequest });
    },
    showBranchCollapse() {
      this.$store.commit("setCompanyCollapseVisible", false);
      this.$store.commit("setBranchCollapseVisible", !this.isBranchCollapseVisible);
    },
  },
};
</script>
