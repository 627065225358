export default [
  { title: "GÜNCEL DURUM", route: "customer-dashboard", key: "customer-dashboard", icon: "HomeIcon", resource: "customer" },
  {
    title: "İNSAN KAYNAKLARI",
    icon: "UsersIcon",
    children: [
      { title: "Çalışanlar", route: "customerEmployees", resource: "customer" },
      { title: "Özlük Dosyaları", resource: "customer" },
      { title: "İzinler", resource: "customer" },
      { title: "Fazla Mesai", resource: "customer" },
      { title: "Zimmetler", resource: "customer" },
      { title: "Avans - Harcama", resource: "customer" },
      { title: "Mesajlar", resource: "customer" },
    ],
  },
  {
    title: "İŞYERİM",
    mdiIcon: "Warehouse",
    children: [
      { title: "İSG Panosu", resource: "customer" },
      { title: "Ekipmanlar", resource: "customer" },
      { title: "Sensör Takip", resource: "customer" },
    ],
  },
  {
    title: "İŞ SAĞLIĞI TAKİBİ",
    mdiIcon: "Doctor",
    children: [
      { title: "Sağlık Raporları", resource: "customer" },
      { title: "Muayeneler", resource: "customer" },
      { title: "Dijital Doktor", resource: "customer" },
      { title: "E-Reçeteler", resource: "customer" },
    ],
  },
  {
    title: "İŞ GÜVENLİĞİ TAKİBİ",
    key: "customer-ws-management",
    mdiIcon: "AccountHardHat",
    children: [
      { title: "Aksiyonlar", route: "customer-isg-actions", resource: "customer" },
      {
        title: "Eğitimler",
        route: "customer-trainings",
        resource: "customer",
      },
      { title: "Planlar", route: "customer-isg-plans", resource: "customer" },
      { title: "Dokümanlar", route: "customer-isg-documents", resource: "customer" },
      { title: "İSG Ziyaretleri", route: "customer-isg-visits", resource: "customer" },
      { title: "İSG Kurulu", resource: "customer" },
    ],
  },
  /*   {
    title: "ÇEVRE TAKİBİ",
    mdiIcon: "Sprout",
    children: [
      { title: "Su Arıtma Ölçümleri", resource: "customer" },
      { title: "Sera Gazı Ölçümleri", resource: "customer" },
      { title: "Tehlikeli Atık Takibi", resource: "customer" },
      { title: "Tehlikeli Atık Listesi", resource: "customer" },
      { title: "Kimyasal Bildirimleri", resource: "customer" },
      { title: "Su Değerleri Listesi", resource: "customer" },
      { title: "Raporlar", resource: "customer" },
    ],
  }, */

  /*   {
    title: "RAPORLAR",
    mdiIcon: "MonitorDashboard",
    children: [
      { title: "İBYS", resource: "customer" },
      { title: "Muhasebe", route: "accounting", resource: "customer" },
      { title: "Teklifler", route: "customer-teklifler", resource: "customer" },
      { title: "Araç Filo Yönetimi", route: "customer-filo", resource: "customer" },
      { title: "Demirbaşlar", resource: "customer" },
      {
        title: "Dijital Fabrika",
        children: [
          { title: "Süreç Yönetimi", resource: "customer" },
          { title: "Sensörler", resource: "customer" },
        ],
      },
      { title: "Yapı İşleri Yönetimi", resource: "customer" },
    ],
  }, */
  {
    title: "FATURA & ÖDEME",
    icon: "FileTextIcon",
    children: [
      { title: "Faturalarım", route: "invoices", resource: "customer" },
      { title: "Hesap Hareketleri", route: "customer-payment", resource: "customer" },
      { title: "Otomatik Ödeme", route: "customer-auto-payment", resource: "customer" },
      { title: "Fatura Tercihleri", resource: "customer" },
    ],
  },

  {
    title: "ARAÇLAR",
    key: "customer-utils",
    mdiIcon: "HammerScrewdriver",
    children: [
      { title: "Video Görüşme", resource: "customer" },
      { title: "Yapılacaklar", resource: "customer" },
      { title: "Takvim", resource: "customer" },
    ],
  },

  {
    title: "AYARLAR",
    key: "customer-settings",
    mdiIcon: "Cogs",
    children: [
      { title: "Firma Bilgilerim", route: "customer-company-info", resource: "customer" },
      { title: "Profil Bilgilerim", route: "customer-profile", resource: "customer" },
      { title: "Kullanıcılar", resource: "customer" },
    ],
  },
];
