<template>
  <!-- v-b-hover="handleHover" -->
  <div
    v-b-hover="handleHover"
    class="avansis"
  >
    <ul class="navigation py-50 position-relative">
      <li class="nav-item">
        <a class="navigation-custom-color d-flex flex-column align-items-center justify-content-center py-25">
          <span>AVANSİS</span>
          <small>Avantajlar Sistemi</small>
        </a>
      </li>
    </ul>
    <transition name="fade">
      <b-card
        v-if="dropdown"
        body-class="p-0"
        class="custom-dropdown mx-50 border rounded position-absolute avansis-dropdown"
      >
        <ul class="navigation py-50 position-relative">
          <li
            v-for="(item, key) in avansis"
            :key="key"
            class="nav-item"
          >
            <b-link
              :to="{ name: item.route }"
              class="d-flex align-items-center"
            >
              <feather-icon :icon="item.icon" />
              <span class="menu-title text-truncate">{{ item.title }}</span>
            </b-link>
            <!--  <feather-icon icon="CircleIcon" />
            <a class="d-flex align-items-center justify-content-center">
              <span>AVANSİS</span>
            </a> -->
          </li>
        </ul>
      </b-card>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropdown: false,
      avansis: [
        {
          title: 'Kargo',
          icon: 'TruckIcon',
        },
        {
          title: 'İş İlanı',
          icon: 'UsersIcon',
        },
        {
          title: 'Akaryakıt',
          icon: 'CircleIcon',
        },
        {
          title: 'Araç Takip',
          icon: 'MonitorIcon',
        },
        {
          title: 'Sigorta',
          icon: 'ShieldIcon',
        },
        {
          title: 'Telekomunikasyon',
          icon: 'SmartphoneIcon',
        },
      ],
    }
  },
  methods: {
    handleHover(item) {
      this.dropdown = item
    },
  },
}
</script>

<style>
.navigation-custom-color {
  background: linear-gradient(118deg, rgb(0, 68, 255), rgba(0, 68, 255, 0.7));
  box-shadow: 0 0 0.625rem 0.0625rem rgba(0, 60, 255, 0.7);
  border-radius: 0.25rem;
  color: #fff !important;
}

.custom-dropdown {
  width: 95%;
  z-index: 999;
}
</style>
