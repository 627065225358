export default [
  {
    title: "GÜNCEL DURUM",
    route: "physician-dashboard",
    key: "physician-dashboard",
    icon: "HomeIcon",
    resource: "physician",
  },
  { title: "TÜM FİRMALARIM", route: "physician-company-info", resource: "physician", icon: "InfoIcon" },
  {
    title: "İŞ GÜVENLİĞİ TAKİBİ",
    key: "physician-ws-management",
    mdiIcon: "AccountHardHatOutline",
    children: [
      { title: "Çalışanlar", route: "physician-isg-employees", resource: "physician" },
      { title: "Tespit / Öneri Defteri", route: "physician-isg-detection-suggestions", resource: "physician" },
      { title: "Gözlemler", route: "physician-isg-observations", resource: "physician" },
      { title: "Olaylar", route: "physician-isg-incidents", resource: "physician" },
      { title: "Eğitimler", route: "physician-isg-trainings", resource: "physician" },
      { title: "Planlar", route: "physician-isg-plans", resource: "physician" },
      { title: "Dokümanlar", route: "physician-isg-documents", resource: "physician" },
      { title: "Ekipmanlar", route: "physician-isg-equipments", resource: "physician" },
      { title: "Atamalar", route: "physician-isg-assignments", resource: "physician" },
    ],
  },
  {
    title: "ZİYARET YÖNETİMİ",
    mdiIcon: "CalendarClock",
    children: [
      { title: "Planlananlar", route: "physician-visits-planed", resource: "physician" },
      { title: "Yapılanlar", route: "physician-visits-completed", resource: "physician" },
      { title: "Yapılmayanlar", route: "physician-visits-not-completed", resource: "physician" },
    ],
  },
  {
    title: "RİSKOMER DİJİTAL",
    mdiIcon: "MonitorDashboard",
    children: [
      { title: "İBYS", resource: "physician" },
      { title: "Uzaktan Eğitim", resource: "physician" },
    ],
  },
  {
    title: "ARAÇLAR",
    key: "physician-utils",
    mdiIcon: "HammerScrewdriver",
    children: [
      { title: "Video Görüşme", resource: "physician" },
      { title: "Yapılacaklar", resource: "physician" },
      { title: "Takvim", resource: "physician" },
    ],
  },
  {
    title: "AYARLAR",
    key: "physician-settings",
    mdiIcon: "Cogs",
    children: [
      { title: "Profil Bilgileri", route: "physician-profile", resource: "physician" },
      { title: "İstek & Öneride Bulun", resource: "physician" },
    ],
  },
];
