<template>
  <b-row>
    <b-col md="4">
      <b-card body-class="d-flex justify-content-center" class="border">
        <b-avatar size="60" variant="success">
          <feather-icon icon="PhoneCallIcon" size="25" />
        </b-avatar>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {};
</script>

<style></style>
