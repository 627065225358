<template>
  <b-button v-if="checkUser" variant="gradient-primary" pill> Yükselt </b-button>
</template>

<script>
export default {
  computed: {
    checkUser() {
      const user = this.$store.getters?.user;
      return user?.role?.group === "customer" && user?.customer?.company?.isCustomerFree;
    },
  },
};
</script>

<style></style>
