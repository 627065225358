export default {
  customer: [
    {
      title: 'İBYS',
      icon: 'HardDriveIcon',
    },
    {
      title: 'İK',
      icon: 'UsersIcon',
    },
    {
      title: 'AVANSİS',
      icon: 'AirplayIcon',
    },
  ],
  physician: [
    {
      title: 'İBYS',
      icon: 'HardDriveIcon',
    },
    {
      title: 'TÜRKPARK',
      icon: 'UsersIcon',
    },
  ],
}
