/* eslint-disable */
// prettier-ignore

export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Access Control', route: { name: 'access-control' }, icon: 'ShieldIcon', isBookmarked: true },
    ],
  },
}
