<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        badge="0"
        badge-classes="bg-secondary"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Bildirimler
        </h4>
        <!-- <b-badge pill variant="light-primary"> 3 Yeni Bildirim </b-badge> -->
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-alert
        class="m-0 mx-1 my-50"
        variant="primary"
        show
      >
        <div class="alert-body">
          <span>Bildirim Yok</span>
        </div>
      </b-alert>
      <!-- <b-link v-for="notification in notifications" :key="notification.subtitle">
        <b-media>
          <template #aside>
            <b-avatar size="32" :src="notification.avatar" :text="notification.avatar" :variant="notification.type" />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>

    <li class="dropdown-menu-footer">
      <b-button
        variant="primary"
        block
      >
        Tüm Bildirimleri Okuyun
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar,
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: 'Revised Order 👋',
        avatar: 'MD',
        subtitle: 'MD Inc. order updated',
        type: 'light-danger',
      },
    ]
    /* eslint-disable global-require */

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style></style>
