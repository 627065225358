<template>
  <b-nav-item-dropdown
    class="dropdown-notification"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <b-avatar
        size="40"
        variant="light-secondary"
      >
        <mdi-icon
          icon="DotsGrid"
          size="28"
        />
      </b-avatar>
    </template>
    <div class="app-scroll-area">
      <b-row>
        <b-col
          v-for="item in navigationData"
          :key="item.title"
          class="app-redirect-card rounded p-1"
          cols="4"
        >
          <span class="d-flex gap-7 align-items-center flex-column">
            <b-avatar
              variant="secondary"
              size="50"
              circle-rounded
            ><feather-icon
              :icon="item.icon"
              size="25"
            /></b-avatar>
            <span class="font-weight-bold">{{ item.title }}</span>
          </span>
        </b-col>
      </b-row>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import appDropdownNavigation from './appDropdownNavigation'

export default {
  computed: {
    navigationData() {
      const items = appDropdownNavigation[this.$store.getters?.user?.role?.group]
      return items || []
    },
  },
}
</script>

<style>
.app-redirect-card {
  cursor: pointer;
}
.app-redirect-card:hover {
  background-color: #f8f8f8;
}

.app-scroll-area {
  max-height: 400px;
  overflow: auto;
  padding: 0rem 1.5rem;
}
</style>
