import customer from "./customer";
import physician from "./physician";

export default [
  {
    title: "Güncel Durum",
    route: "dashboard",
    key: "dashboard",
    icon: "HomeIcon",
  },
  ...customer,
  ...physician,
  /*   {
    title: "Yapılacaklar",
    route: "apps-todo",
    icon: "CheckSquareIcon",
    roles: [...physicians, "customer"],
  },
  {
    title: "Takvim",
    route: "app-calendar",
    icon: "CalendarIcon",
    roles: [...physicians, "customer"],
  }, */
];
