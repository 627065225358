<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[{ expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) }, skin === 'semi-dark' ? 'menu-dark' : 'menu-light']"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed" :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item" style="flex: 1">
            <b-link class="navbar-brand" :class="{ 'justify-content-center': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) }" to="/">
              <b-img v-show="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)" width="150" :src="appLogoImageExpanded" alt="logo" />
              <b-img v-show="isVerticalMenuCollapsed && !isMouseHovered" width="30" :src="appLogoImage" alt="logo" />
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon icon="XIcon" size="20" class="d-block d-xl-none" @click="toggleVerticalMenuActive" />
              <feather-icon :icon="collapseTogglerIconFeather" size="24" class="d-none d-xl-block collapse-toggle-icon" @click="toggleCollapsed" />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- Shadow -->
    <!-- <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" /> -->

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items :items="navMenuItems" class="navigation navigation-main" />
      <hr class="mt-0" />
      <div v-if="user" class="d-flex justify-content-between align-items-center mx-2 no-wrap">
        <div class="d-flex gap-7 align-items-center">
          <b-avatar size="30" variant="secondary" />
          <div v-show="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)">
            <p style="text-transform: capitalize" class="fs-13 font-weight-bolder mb-0">
              {{ user.fullname }}
            </p>
            <span v-if="user.role.group === 'customer' && selectedCompany && selectedCompany.name" class="fs-12">{{ $fn(selectedCompany.name, 20) }}</span>
            <span v-else class="fs-12">{{ user.role.value }}</span>
          </div>
        </div>
      </div>
      <hr />
      <div @click="logout" class="cursor-pointer d-flex gap-10 no-wrap nav-margin">
        <feather-icon size="18" icon="LogOutIcon" />
        <span v-show="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)" class="fs-15 no-wrap"> Çıkış Yap </span>
      </div>
      <hr />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import makeRequest from "@/views/Dashboard/requests/makeRequest.vue";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    FeatherIcon,
    makeRequest,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    /*     sidebarItems() {
      switch (this.$store.getters?.user?.role?.group) {
        case "customer":
          return require("@/navigation/vertical/customer")?.default;
        case "physician":
          return require("@/navigation/vertical/physician")?.default;
        default:
          return [];
      }
    }, */
  },
  methods: {
    logout() {
      this.$store.dispatch("logout", { sendLogoutReq: true });
    },
  },
  setup(props) {
    const { isMouseHovered, isVerticalMenuCollapsed, collapseTogglerIcon, toggleCollapsed, updateMouseHovered } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const pinIcon = computed(() => (collapseTogglerIcon.value === "unpinned" ? require("./pin-icon.svg") : require("./unpin-icon.svg")));

    const collapseTogglerIconFeather = computed(() => (isVerticalMenuCollapsed.value ? "ChevronsRightIcon" : "ChevronsLeftIcon"));

    // App Name
    const { appName, appLogoImage, appLogoImageExpanded } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoImageExpanded,

      pinIcon,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.filter-pin {
  filter: invert(20%) sepia(88%) saturate(7279%) hue-rotate(358deg) brightness(97%) contrast(109%);
  /* filter: brightness(0) invert(1); */
}

.no-wrap {
  white-space: nowrap;
}
.nav-margin {
  margin-left: 25px;
}
</style>
